import React from 'react'
import Page from '../page'
import Tags from '../../atoms/tags'
import Search from '../../molecules/search'
import ArticlesList from '../../organisms/article-list/'

const Article404 = (props) => {
  return (
    <Page>
        <div className="row t-404-page">
            <div className="col-md-8 t-404-page__content">
              <h1>Nothing here to see</h1>
              <h3>As you are here you can take a look at the latest articles</h3>
              <ArticlesList posts={props.posts}/>
            </div>
            <div className="col-md-4">
              <Search classNames={'t-404-page'} cols="12" lang="en" />
              <h3>Tags</h3>
              <Tags tags={props.tags}/>
            </div>
          </div>  
    </Page>
  )
}

export default Article404
