import React from 'react'
import { graphql } from 'gatsby'
import Article404 from '../components/templates/404/'

const NotFoundPage = (props) => {
  const posts = props.data.allMarkdownRemark
  const tags = []
  
  posts.edges.map(({ node }) => (
    node.frontmatter.tags.map(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag)
      }
  })))

  const pagePosts = []
  posts.edges.map(({ node }) => pagePosts.push(node))

return (
  <Article404 posts={pagePosts} tags={tags}/>
)
}

export default NotFoundPage

export const list404Query = graphql`
  query List404Query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: {frontmatter: {publish: {ne: false}}},
      limit: 3
      ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            tags
            url
          }
        }
      }
    }
  }
`